import {Component, ElementRef, HostBinding, OnDestroy, Renderer2} from '@angular/core';
import {DocumentService} from '@shared/modules/content/services/document/document.service';
import {ButtonTypeEnum} from '@shared/button/button.directive';
import {ThemeHelper} from '@shared/helpers/theming.helper';
import {BehaviorSubject, OperatorFunction, Subscription} from 'rxjs';
import {LibraryActions, LibrarySelectors} from '@store/library';
import {BookDetailInterface, BookSourceEnum} from '@shared/interfaces/book.interface';
import {AssignmentContentType, AssignmentFieldInterface, AssignmentInterface, AttachmentInterface} from '@shared/interfaces/assignment.interface';
import {DocumentAssignmentService} from '@shared/modules/content/services/assignment/assignment.service';
import {AssignmentAnswersStateService} from '@shared/modules/content/services/show-answers/answers-state.service';
import {filter} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {RoleEnum} from '@core/security/enum/role.enum';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {
    ReloadAssignmentService,
} from '@shared/modules/content/modules/components/assignment/service/reload-assignment.service';

export interface assignmentStateInterface {
    reviewedOn?: Date;
    handedIn?: Date | string;
    contentType?: string
}

@Component({
    selector: '.document-opdracht',
    templateUrl: 'assignment.component.html',
})
export class DocumentAssignmentComponent implements OnDestroy {
    #subscriptions: Subscription[] = [];

    #assignmentSubject: BehaviorSubject<AssignmentInterface | undefined> = new BehaviorSubject<AssignmentInterface | undefined>(undefined);

    #documentAnswersStateService: AssignmentAnswersStateService;

    #authorizationService: AuthorizationService;

    #assignment!: Readonly<AssignmentInterface>;

    #bookSource!: BookSourceEnum;

    attachments?: AttachmentInterface[];

    assignmentState?: assignmentStateInterface;

    assignmentDocumentDpsId?: string;

    readonly buttonType = ButtonTypeEnum;

    readonly assignmentTypes = AssignmentContentType;

    feedbackFieldId?: number;

    @HostBinding('class')
    elementClass: string = 'document__reference';

    @HostBinding('class.document-inline-verwijzing--expand')
    assignmentCollapsable: boolean = false;

    bookUuid!: string;

    chapterUuid!: string;

    isLoading: boolean = true;

    isRoleHO: boolean = false;

    hasCorrectAnswers: boolean = false;

    assignmentType: AssignmentContentType = AssignmentContentType.Normal;

    bookMethodColor?: string;

    @HostBinding('style.font-family')
    font?: string;

    constructor(
        documentAnswersStateService: AssignmentAnswersStateService,
        authorizationService: AuthorizationService,
        private documentAssignmentService: DocumentAssignmentService,
        private reloadAssignmentService: ReloadAssignmentService,
        private store: Store,
        public element: ElementRef,
        private documentService: DocumentService,
        private themeHelper: ThemeHelper,
        private renderer: Renderer2,
    ) {
        const dpsId: string = this.#getDpsId();

        this.#documentAnswersStateService = documentAnswersStateService;
        this.#authorizationService = authorizationService;

        this.#subscriptions.push(
            this.store.select(LibrarySelectors.selectBook).subscribe(book => this.#handleSelectLibraryBook(book)),
            this.documentAssignmentService.selectByDpsId(dpsId).subscribe((assignment: AssignmentInterface) => this.#updateAssignmentState(assignment)),
        );

        this.documentAssignmentService.subscribeToReset(removeAnswers => this.reloadAssignmentComponent());

        if (this.assignmentType === AssignmentContentType.Normal) {
            this.documentAssignmentService.selectContext(context => {
                this.#handleSelectContext(context.bookUuid, context.chapterUuid, dpsId)
            });
        }

        this.isRoleHO = this.#authorizationService.isGranted(RoleEnum.RoleOrganizationHo);
    }

    ngOnDestroy(): void {
        this.#documentAnswersStateService.remove(this.#assignment);
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
        this.documentService.removeResourceById(this.element.nativeElement.id);
    }

    subscribeToAssignment(callback: (assignment: AssignmentInterface) => void): Subscription {
        return this.#assignmentSubject
            .pipe(filter((assignment?: AssignmentInterface): boolean => undefined !== assignment) as OperatorFunction<AssignmentInterface | undefined, AssignmentInterface>)
            .subscribe((assignment: AssignmentInterface) => callback(assignment));
    }

    reloadAssignmentComponent(): void {
        this.updateDocumentAttachment({state: 'reload'});
    }

    updateDocumentAttachment(data: { state: string, attachmentId?: string | undefined}): void {
        const bookUuid: string = this.bookUuid;
        const chapterUuid: string = this.chapterUuid;
        const documentDpsId: string = this.#getDpsId();

        if(!bookUuid || !chapterUuid || !documentDpsId) {
            return;
        }

        if (this.isRoleHO) {
            this.isLoading = true;
            this.#updateDocumentAttachmentForHo(data);

            return;
        }
        this.store.dispatch(LibraryActions.fetchDocument({bookUuid, chapterUuid, documentDpsId: documentDpsId}));
        this.#disableScrollToTop();
    }

    #updateDocumentAttachmentForHo(data: { state: string, attachmentId?: string | undefined}) {
        if (data.state === 'delete' && this.attachments) {
            this.attachments = [...this.attachments.filter(att => att.metadata.id !== Number(data.attachmentId))];
            this.reloadAssignmentService.triggerReload();

            this.isLoading = false;

            return;
        }

        if (data.state === 'reload') {
            this.reloadAssignmentService.triggerReload();
            this.isLoading = false;

            return;
        }
    }

    getFields(): AssignmentFieldInterface[] {
        return this.#assignment.fields;
    }

    /**
     * TODO BD-1444: This method contains business logic that should be handled by the backend.
     */
    #updateAssignmentState(assignment: AssignmentInterface): void {
        if (assignment && assignment.attachments) {
            this.attachments = assignment.attachments;
            this.assignmentDocumentDpsId = assignment.dpsId;
        }

        this.assignmentState = {
            reviewedOn: assignment.reviewedOn ?? undefined,
            handedIn: assignment.session?.handedIn ?? undefined,
            contentType: assignment.contentType ?? undefined,
        };

        const correctAnswers = assignment.answersV2?.correctAnswers;

        this.#documentAnswersStateService.set(assignment);
        this.#assignmentSubject.next(this.#assignment = assignment);
        this.assignmentType = assignment.contentType;
        this.hasCorrectAnswers = (correctAnswers?.length ?? 0) > 0;
        this.isLoading = false;

        /**
         * @see https://xiponlineapplications.atlassian.net/browse/BD-2270
         *
         * When dealing with DPS content, assignments do not have the required wrapper around its assignment fields.
         * To work around the issue, we'll add feedback to the first field and display it at the top of the assignment.
         */
        if (BookSourceEnum.Dps === this.#bookSource) {
            this.feedbackFieldId = assignment?.fields[0]?.fieldId;
        }
    }

    #handleSelectLibraryBook(book?: BookDetailInterface): void {
        if (undefined === book) {
            return;
        }

        this.#bookSource = book.source;
        this.font = book.font;

        const bookColor: string | undefined = this.bookMethodColor = book.color;

        if (undefined !== bookColor) {
            this.themeHelper.updateDocumentMarkTheming(this.element, bookColor);
        }
    }

    #handleSelectContext(bookUuid?: string, chapterUuid?: string, documentDpsId?: string): void {
        if (undefined === bookUuid || undefined === chapterUuid || undefined === documentDpsId) {
            return;
        }

        // Safe to try and fetch, the call won't proceed if the assignment is already present in the state.
        this.documentAssignmentService.fetchByContext(this.bookUuid = bookUuid, this.chapterUuid = chapterUuid, documentDpsId);
    }

    #getDpsId(): string {
        const dpsId: string | undefined = (this.element.nativeElement as HTMLElement).getAttribute('data-dpsid') ?? undefined;

        if (undefined === dpsId) {
            throw new Error('Unable to load assignment, missing dpsID.');
        }

        return dpsId;
    }

    #disableScrollToTop() {
        this.renderer.setProperty(document.body, 'scrollTop', 0);
    }
}
