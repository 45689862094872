import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ScrollService} from '@shared/scroll-to/service/scroll-to.service';
import {ScrollDirectionEnum} from '@shared/scroll-to/scroll-direction.enum';

@Component({
    selector: 'app-scroll-to',
    templateUrl: './scroll-to.component.html',
    styleUrls: ['./scroll-to.component.scss'],
})
export class ScrollToComponent implements OnChanges {
    @Input() scrollDirection: ScrollDirectionEnum = ScrollDirectionEnum.Bottom;

    isAtTop: boolean = true;

    constructor(
        private scrollService: ScrollService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.scrollDirection) {
            this.updateIconState();
        }
    }

    toggleScrollDirection(): void {
        switch (this.scrollDirection) {
            case ScrollDirectionEnum.Top:
                this.isAtTop = false;
                this.scrollService.triggerScrollToTop();
                break
            case ScrollDirectionEnum.Bottom:
                this.isAtTop = true;
                this.scrollService.triggerScrollToBottom();
                break;
        }
    }

    private updateIconState(): void {
        switch (this.scrollDirection) {
            case ScrollDirectionEnum.Top:
                this.isAtTop = false;
                break;
            case ScrollDirectionEnum.Bottom:
                this.isAtTop = true;
                break;
        }
    }
}
