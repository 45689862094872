import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentTypeEnum} from '@shared/enums/document-type.enum';
import {LibraryContextInterface, LibrarySelectors} from '@store/library';
import {AuthorizationService} from '@core/security/services/authorization.service';
import {GeneralActions, GeneralSelectors} from '@store/general';
import {TableOfContentsDocumentInterface} from '@shared/interfaces/table-of-contents.interface';
import {Subscription} from 'rxjs';
import {ActionsSubject, Store} from '@ngrx/store';
import {ofType} from '@ngrx/effects';
import {RoleEnum} from '@core/security/enum/role.enum';
import {NavigationRoleEnum} from '@core/navigation/navigation.component';

enum TocItemTypeSubRoute {
    ContentExam = 'content-exams',
    Document = 'documents',

    /** @deprecated Remindo will be removed in the future */
    RemindoExam = 'exams',
}

@Component({
    selector: 'app-chapter-toc-item[item]',
    templateUrl: './chapter-toc-item.component.html',
    styleUrls: ['./chapter-toc-item.component.scss'],
})
export class ChapterTocItemComponent implements OnInit, OnDestroy {
    #subscriptions: Subscription[] = [];

    #bookUuid!: string;

    #chapterUuid!: string;

    #documentDpsId!: string;

    @HostBinding('class')
    readonly classes: string[] = ['nav-item'];

    readonly documentTypeEnum = DocumentTypeEnum;

    public readonly roles = RoleEnum;

    @Input()
    item!: TableOfContentsDocumentInterface;

    @Input()
    @HostBinding('class.nav-item--bullets')
    showBullets!: boolean;

    @Input()
    showChildren!: boolean;

    expanded: boolean = false;

    menuExpanded!: boolean;

    /** @deprecated Remindo will be removed in the future */
    isRemindoExam?: boolean;

    isContentExam?: boolean;

    isTermTrain?: boolean;

    routerLink?: string[];

    /**
     * TODO BD-903: Remove AuthorizationService injection
     */
    constructor(private store: Store, private authorizationService: AuthorizationService, actionsSubject: ActionsSubject) {
        this.showChildren = undefined !== this.showChildren ? this.showChildren : authorizationService.isGranted(NavigationRoleEnum.RoleNavigationItemChildren);

        this.#subscriptions.push(
            this.store.select(GeneralSelectors.selectNavigationSecondExpanded).subscribe(expanded => this.#handleGeneralSelectMenuExpanded(expanded)),
            actionsSubject.pipe(ofType(GeneralActions.clickTableOfContentsItem)).subscribe(properties => this.#handleGeneralClickTableOfContentsItem(properties.dpsId)),
        );
    }

    ngOnInit(): void {
        this.#subscriptions.push(
            this.store.select(LibrarySelectors.selectContext).subscribe(context => this.#handleSelectContext(context)),
        );
    }

    ngOnDestroy(): void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Toggle item expand when item has children and dispatch item dpsId, collapse TOC when clicking item that has no children
     */
    handleItemClick(): void {
        const expandMenu: boolean = 0 !== this.item.children.length && this.showChildren;

        if (expandMenu && (this.menuExpanded || !this.menuExpanded && !this.expanded)) {
            this.expanded = !this.expanded;
        }

        this.store.dispatch(GeneralActions.toggleNavigationSecondExpanded({value: expandMenu}));
        this.store.dispatch(GeneralActions.clickTableOfContentsItem({dpsId: this.item.dpsId}));
    }

    /**
     * This method allows us toggling all other menu items
     * TODO TD-903: Remove showChildren specific check
     */
    #handleGeneralClickTableOfContentsItem(dpsId: string): void {
        if (dpsId !== this.item.dpsId && !this.#isNestedChild(dpsId, this.item.children)) {
            this.expanded = false;
        }
    }

    /**
     * Keep track of TOC visible state and restore expanded state on item when closing menu
     */
    #handleGeneralSelectMenuExpanded(expanded: boolean): void {
        this.menuExpanded = expanded;
        if (!this.menuExpanded) {
            this.expanded = this.#hasActiveItem(this.item?.children ?? []);
        }
    }

    #handleSelectContext(context: LibraryContextInterface): void {
        const bookUuid: string | undefined = context?.bookUuid;
        const chapterUuid: string | undefined = context?.chapterUuid;
        const documentDpsId: string | undefined = context?.documentDpsId;

        if (undefined === bookUuid || undefined === chapterUuid || undefined === documentDpsId) {
            return;
        }

        const item: TableOfContentsDocumentInterface | undefined = this.item;

        if (undefined === item) {
            throw new Error('Unable to handle context, item is undefined');
        }

        this.#bookUuid = bookUuid;
        this.#chapterUuid = chapterUuid;
        this.#documentDpsId = documentDpsId;
        this.isRemindoExam = [DocumentTypeEnum.RemindoPractiseExam].includes(item.type);
        this.isContentExam = DocumentTypeEnum.ContentExam === item.type || DocumentTypeEnum.ContentPractiseExam === item.type;
        this.isTermTrain = item.title.toLowerCase().includes(DocumentTypeEnum.Term);
        this.expanded = this.#hasActiveItem(item.children);
        this.routerLink = this.#generateItemUrl(item);

        this.showBullets = undefined !== this.showBullets
            ? this.showBullets: this.authorizationService.isGranted(NavigationRoleEnum.RoleNavigationItemBullet) && !this.isRemindoExam && !this.isTermTrain && !this.isContentExam;
    }

    #hasActiveItem(children: TableOfContentsDocumentInterface[]): boolean {
        return children.some(child => {
            return child.dpsId === this.#documentDpsId || this.#hasActiveItem(child.children);
        }) ?? false;
    }

    #isNestedChild(dpsId: string, children: TableOfContentsDocumentInterface[]): boolean {
        return children.some(child => {
            return child.dpsId === dpsId;
        }) ?? false;
    }

    #generateItemUrl(item: TableOfContentsDocumentInterface): string[] {
        return ['/books', this.#bookUuid, 'chapters', this.#chapterUuid, this.#getItemTypeRoutePart(item), item.dpsId];
    }

    #getItemTypeRoutePart(item: TableOfContentsDocumentInterface): string {
        switch (item.type) {
            case DocumentTypeEnum.RemindoExam:
            case DocumentTypeEnum.RemindoPractiseExam:
                return TocItemTypeSubRoute.RemindoExam;

            case DocumentTypeEnum.ContentExam:
            case DocumentTypeEnum.ContentPractiseExam:
                return TocItemTypeSubRoute.ContentExam;

            default:
                return TocItemTypeSubRoute.Document;
        }
    }
}
