import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export enum ScrollTypeEnum {
    Smooth = 'smooth',
    Instant = 'end',
}

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    private scrollToTopSource = new Subject<void | ScrollTypeEnum>();

    private scrollToBottomSource = new Subject<void | ScrollTypeEnum>();

    scrollToTop$ = this.scrollToTopSource.asObservable();

    scrollToBottom$ = this.scrollToBottomSource.asObservable();

    triggerScrollToTop(scrollType: ScrollTypeEnum = ScrollTypeEnum.Smooth): void {
        this.scrollToTopSource.next(scrollType);
    }

    triggerScrollToBottom(scrollType: ScrollTypeEnum = ScrollTypeEnum.Smooth): void {
        this.scrollToBottomSource.next(scrollType);
    }
}
