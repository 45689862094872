export enum DocumentTypeEnum {
    Assignment = 'assignment',
    Theory = 'theory',
    Question = 'question',
    RemindoExam = 'exam',
    RemindoPractiseExam = 'practice-exam',
    ContentExam = 'content-exam',
    ContentPractiseExam = 'content-practice-exam',
    Source = 'source',
    Term = 'begrippentrainer',
    Document = 'documents',
    Exams = 'exams',
}
