import {Action, createReducer, on} from '@ngrx/store';
import {AssignmentActions} from '@store/assignment/assignment.actions';
import {AssignmentLegacyDocumentMap} from '@store/assignment/assignment-legacy-document.map';
import {LibraryActions} from '@store/library';
import {ProgressActions} from '@store/progress';
import {AssignmentAnswerInterface, AssignmentInterface} from '@shared/interfaces/assignment.interface';

export interface AssignmentsInterface {
    [key: string]: AssignmentInterface;
}

export interface AssignmentState {
    assignments: AssignmentsInterface;
    assignmentLegacyDocuments: AssignmentLegacyDocumentMap;
}

export const initialState: AssignmentState = {
    assignments: {},
    assignmentLegacyDocuments: new AssignmentLegacyDocumentMap(),
};

export interface AssignmentContextInterface {
    bookUuid: string;
    chapterUuid: string;
    documentDpsId: string;
    legacyDocument?: string;
}

/**
 * @deprecated we should receive entire state from backend after altering it
 */
const updateAssignmentsAnswer = (state: AssignmentState, answer: AssignmentAnswerInterface): AssignmentState => {
    const assignments = {...state.assignments};
    const assignmentDocumentDpsId: string = answer.assignmentDocumentDpsId;
    const assignment: AssignmentInterface = assignments[assignmentDocumentDpsId];
    const answers = [...assignment.answers.filter(answer => answer.fieldId !== answer.fieldId), answer];

    assignments[assignmentDocumentDpsId] = {...assignment, hasAnswer: 0 !== answers.length, answers};

    return {...state, assignments};
};

const updateAssignmentsState = (state: AssignmentState, assignment: AssignmentInterface): AssignmentState => {
    const assignments = {...state.assignments};

    assignments[assignment.dpsId] = assignment;

    return {...state, assignments};
};

const removeAssignment = (state: AssignmentState, assignment: AssignmentInterface): AssignmentState => {
    const newAssignments: AssignmentsInterface = {};

    Object.values(state.assignments).forEach((stateAssignment: AssignmentInterface): void => {
        if (stateAssignment.dpsId !== assignment.dpsId) {
            newAssignments[stateAssignment.dpsId] = stateAssignment;
        }
    });

    return {...state, assignments: newAssignments};
}

export const reducer = createReducer(
    initialState,
    on(AssignmentActions.remove, (state, assignment) => removeAssignment(state, assignment)),
    on(LibraryActions.fetchDocumentProgressSuccess, (state, props) => updateAssignmentsState(state, props.document.assignment)),
    on(AssignmentActions.fetchByAssignmentContextSuccess, (state, props) => ({
        ...updateAssignmentsState(state, props.document.assignment),
        assignmentLegacyDocuments: state.assignmentLegacyDocuments.insert(props.document.assignment.dpsId, props.document.legacyDocument),
    })),
    on(AssignmentActions.handInSuccess, (state, assignment) => updateAssignmentsState(state, assignment)),
    on(AssignmentActions.autoCheckSuccess, (state, assignment) => updateAssignmentsState(state, assignment)),
    on(AssignmentActions.saveAnswerSuccess, (state, props) => updateAssignmentsAnswer(state, props.assignmentAnswer)),
    on(
        AssignmentActions.saveReviewSuccess,
        AssignmentActions.removeAnswersSuccess,
        AssignmentActions.redoAnswersSuccess,
        (state, assignment) => updateAssignmentsState(state, assignment)),
    on(
        ProgressActions.saveReviewSuccess,
        ProgressActions.saveUpdatedReviewSuccess,
        ProgressActions.removeAnswersSuccess,
        (state, progressReview) => updateAssignmentsState(state, progressReview.assignment),
    ),
    on(AssignmentActions.createFeedbackSuccess, (state, props) => updateAssignmentsState(state, props.document.assignment)),
    on(AssignmentActions.respondFeedbackSuccess, (state, props) => updateAssignmentsState(state, props.document.assignment)),
);

export const assignmentReducer = (
    state: AssignmentState | undefined,
    action: Action,
): AssignmentState => {
    return reducer(state, action);
};
