import {Action, createReducer, on} from '@ngrx/store';
import {LibraryActions} from '@store/library';
import {BookDetailInterface, BookListInterface} from '@shared/interfaces/book.interface';
import {ChapterProgressInterface} from '@shared/interfaces/chapter.interface';
import {DocumentDetailsInterface} from '@shared/interfaces/document.interface';
import {DocumentSourceDataInterface, TeacherSourceDataInterface} from '@core/services/source/source.service';

export interface LibraryState {
    bookList?: BookListInterface;
    chapters?: ChapterProgressInterface[];
    book?: BookDetailInterface;
    chapter?: ChapterProgressInterface;
    document?: DocumentDetailsInterface;
    documentSourceData?: DocumentSourceDataInterface;
    teacherSourceData?: TeacherSourceDataInterface;
    id?: number;
    showToc?: boolean;
}

const initialState: LibraryState = {};

const reducer = createReducer(
    initialState,
    on(LibraryActions.unsetBook, state => ({...state, book: undefined, chapter: undefined, document: undefined, chapters: []})),
    on(LibraryActions.fetchBookSuccess, (state, book) => ({...state, book})),
    on(LibraryActions.fetchBooksSuccess, (state, bookList) => ({...state, bookList})),
    on(LibraryActions.patchBookIndexSuccess, (state, bookList) => ({...state, bookList})),
    on(LibraryActions.unsetChapter, state => ({...state, chapter: undefined, chapters: undefined, document: undefined})),
    on(LibraryActions.unsetToc, state => ({...state, showToc: false})),
    on(LibraryActions.setToc, state => ({...state, showToc: true})),
    on(LibraryActions.unsetDocument, state => ({...state, document: undefined, showToc: false})),
    on(LibraryActions.fetchChapterSuccess, (state, {chapter}) => ({...state, chapter})),
    on(LibraryActions.fetchChaptersSuccess, (state, {chapters}) => ({...state, chapters})),
    on(LibraryActions.unsetSources, state => ({...state, sources: undefined})),
    on(LibraryActions.fetchSourcesSuccess, (state, documentSourceData) => ({...state, documentSourceData})),
    on(LibraryActions.fetchDocumentSuccess, (state, {document}) => ({...state, document})),
    on(LibraryActions.unsetSourcesTeacherMaterials, state => ({...state, sources: undefined})),
    on(LibraryActions.fetchSourcesTeacherMaterialsSuccess, (state, teacherSourceData) => ({...state, teacherSourceData})),
    on(LibraryActions.fetchDocumentProgress, state => ({...state, showToc: false})),
    on(LibraryActions.fetchDocument, state => ({...state, showToc: true})),
);

export const libraryReducer = (
    state: LibraryState | undefined,
    action: Action,
): LibraryState => {
    return reducer(state, action);
};
